import ApplicationController from './application_controller'

export default class extends ApplicationController

  # Show file dialog
  show_dialog: (event) ->
    $('dialog').get(0).showModal()
    event.preventDefault()

  # Load transaction files
  close: (event) -> $('dialog').get(0).close()
