import ApplicationController from './application_controller'

export default class extends ApplicationController
  @values = {width: Number, height: Number, borderWidth: Number, borderHeight: Number, reload: Boolean, remove: Boolean, \
             type: String, path: String, runParams: String, confirmExit: Boolean, exitMessage: String}
  @targets = ['hint', 'controls']

  # Create functions for review controls
  initialize: ->
    window.runTutor = (path, label) ->
      $('.run').attr('data-tutors-path-param': path, 'data-tutors-label-param': label || ''); $('.run').click()
    if @hasControlsTarget
      $controls = $(@controlsTarget)
      window.showTutorParameter = (key) -> $('iframe#interface').data('params')[key]
      window.setTutorParameter = (key, value) -> $('iframe#interface').data('params')[key] = value
      $(window).on 'message', (event) ->
        $controls.prop('contentWindow').postMessage event.originalEvent.data, window.location.origin

  # Run Tutors
  runTutor: ({params}) ->
    # logTime 'Run Button Pressed'
    if not ($dialog = $('dialog')).length or $dialog.find('iframe').attr('src') isnt params.path
      $dialog.remove() if $dialog.length
      $('body').append $dialog = $('<dialog>', class: 'tutor').
        append($('<h2>', class: 'visually_hidden', text: params.label)).
        append($('<iframe>', name: 'tutor', class: 'tutor', allowfullscreen: true, title: 'Tutor Outer Frame', src: params.path))
      $dialog.on('close', -> location.reload(true)) if @hasReloadValue
    if @hasRemoveValue then $dialog.get(0).showModal() else $dialog.get(0).show()

  # Create tutor interface
  createTutor: ->
    if @typeValue in ['html', 'htm']
      $(@element).find('#tutor_container').html($('<iframe>',
        name: 'interface', id: 'interface', allowfullscreen: true, title: 'Tutor Interface', \
        src: @pathValue, 'data-params': @runParamsValue))
      $dialog = $(window.frameElement).closest('dialog')
      $dialog.css '--inline-size', @widthValue + 2 * @borderWidthValue + 'px' if @hasWidthValue
      $dialog.css '--block-size', @heightValue + 2 * @borderHeightValue + 20 + 'px' if @hasHeightValue

  # Confirm closing the tutor
  confirmCloseTutor: ->
    if not @hasRemoveValue or not @confirmExitValue or confirm(@exitMessageValue)
      $dialog = $(window.frameElement).closest('dialog')
      $dialog.get(0).close()
      $dialog.remove() if @hasRemoveValue

  # Toggle display name hint
  toggleHint: -> $(@hintTarget).toggleClass('tooltip')
